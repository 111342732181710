import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import butterfy from "../Images/png/Butterfly-wings-flap-unscreen.gif";
import Axios from "axios";
import serviceimage1 from "../Images/service/CRF Design.jpg";
import serviceimage2 from "../Images/service/ METADATA LIBRARY.jpg";
import serviceimage3 from "../Images/service/DATA TRANSFORMATION.jpg";
import serviceimage4 from "../Images/service/EDC CLINICAL TRIALS.png";
import serviceimage5 from "../Images/service/SAS.png";
import Modals from "./Modals";
import {url} from "../constants";

const Services = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    const { data } = await Axios.get(
      url+"/api/get_service/"
    );
    const products = data;

    setProducts(products.data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      <section className="banner-services">
        <div>
          <div className="row justify-content-end">
            <div className="col-sm-6">
              <div className="product-text px-lg-5 abc">
                <h3>Services we offer</h3>
                <p>
                  Our team of experts is capable of handling all activities
                  related to clinical trials from a domain and technology
                  perspective. We facilitate end-to-end processes in the
                  clinical trial space.
                </p>
                <button
                  className="btn btn-submit"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="px-3">
        {products.map((product) => (
          <div className="cards-sevices  container px-0 ">
            {product.for_check == 0 ? (
              <div className="row  align-items-center">
                <div className="col-sm-6 p-0">
                  <div className="service-images-box-1">
                    <img className="w-full" src={url + product.service_img} />
                  </div>
                </div>
                <div className="col-sm-6 px-5">
                  <div className="product-text ">
                    <h3>{product.title}</h3>
                    <img className="box" src={butterfy} />

                    <p>{parse(product.description)}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row  align-items-center">
                <div className="col-sm-6 px-5">
                  <div className="product-text ">
                    <h3>{product.title}</h3>
                    <img className="box" src={butterfy} />

                    <p>{parse(product.description)}</p>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  <div className="service-images-box">
                    <img className="w-full" src={url + product.service_img} />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </section>

      <section className="text-center mb-5 px-3">
        <div className="product-text ">
          <p>Want to get in touch?</p>
          <button
            className="btn btn-submit"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Contact Us
          </button>
        </div>
      </section>
      <Modals />
    </div>
  );
};

export default Services;
