import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import butterfy from "../Images/png/Butterfly-wings-flap-unscreen.gif";
import tools from "../Images/Banner/TOOLS2.png";
import Axios from "axios";
import {url} from "../constants";
const Product = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    const { data } = await Axios.get(
      url+"/api/get_product/"
    );
    const products = data;

    setProducts(products.data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="body-product">
      <section className="banner-product py-5">
        <div className="py-5 mt-2 ml-4">
          <div className="row py-3 mt-2 ml-4" >
              <div className="product-text px-lg-5 col-7">
                <h3>Products</h3>
                <p>
                  Our products helps at every step of the clinical trial<br/> from
                  <b> protocol creation to publication.</b>
                </p>
              </div>
          </div>
          {/* <div className="py-0 px-lg-5">
            <img src={tools} width={'50%'}/>
          </div> */}
          <div className="py-5 mt-2 px-lg-5 col-6">
          <button
            className="btn btn-submit"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Request a Demo
          </button>
          </div>
        </div>
      </section>
      <section className="py-0 px-4">
        {products.map((product, index) => (
          <div className="cards-sevices container px-0">
            {product.for_check == 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-6 p-0">
                  <div className="product-images-box-1">
                    <img className="w-full" src={url + product.product_img} style={{objectFit: 'contain'}} />
                  </div>
                </div>
                <div className="col-sm-6 mb-4 mt-4">
                  <div className="product-text">
                    <h3>{product.title}</h3>
                    <img className="box mb-2" src={butterfy} />

                    <p>{parse(product.description)}</p>
                    <div className="product-list-points">
                      <a href={product.for_detail} className="btn btn-submit py-2" >Know More</a>
                      {/* <div className="py-2">
                        <button
                          data-bs-toggle="modal2"
                          data-bs-target="#exampleModal2"
                          className="btn btn-outline-primary py-2"
                        >
                          View Datasheet
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row  align-items-center">
                <div className="col-sm-6 ">
                  <div className="product-text p-4">
                    <h3>{product.title}</h3>
                    <img className="box" src={butterfy} />

                    <p>{parse(product.description)}</p>
                    <div className="product-list-points">
                    <a href="/dcp" className="btn btn-submit py-2" >Know More</a>

                      <a href="">View Datasheet</a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  <div className="product-images-box" >
                    <img src={url + product.product_img}/>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </section>

      <section className="text-center mb-5">
        <div className="product-text px-4 ">
          <p>Want to try our product?</p>
           <button
            className="btn btn-submit"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Request a Demo
          </button>
        </div>
      </section>
    </div>
  );
};

export default Product;
