import React from "react";
import logo from "../Images/Banner/logo.png"

function Footer(props) {
  return (
    <div>
      <section className="section-padding footer-mid pt-5">
        <div className="container">
          <div className="row justify-content-between align-items-baseline">
            <div className="col-sm-3">
              <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0">
                <div className="logo mb-30">
                  <a className="navbar-brand" href="#">
                    <img src={logo} />
                  </a>
                  <p className="font-lg text-heading mt-3">
                    Lifio has an innovative AI-driven SaaS platform that
                    leverages Artificial Intelligence (AI), Machine Learning
                    (ML), Natural Language Processing (NLP) and deep learning to
                    automate certain processes of clinical trials
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="footer-link-widget col-sm-4">
                  <h4 className="widget-title">TOOLS</h4>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                    <li>
                      <a href="/cdd">Clinical Digital Document (CDD)</a>
                    </li>
                    <li>
                      <a href="/csat">Clinical Study Automation Tool (CSAT)</a>
                    </li>
                    <li>
                      <a href="/sdtm">SDTM Mapping</a>
                    </li>
                    <li>
                      <a href="/dcp">Data Capture Platform (DCP)</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-link-widget col-sm-4">
                  <h4 className="widget-title">SERVICES</h4>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                    <li>
                      <a href="/services">Clinical Document Creation</a>
                    </li>
                    <li>
                      <a href="/services#metadata-library">Metadata Libary</a>
                    </li>
                    <li>
                      <a href="/services#edc-build">EDC Builds</a>
                    </li>
                    <li>
                      <a href="/services#sas-sdtm">SAS SDTM Programming</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-link-widget col-sm-4">
                  <h4 className="widget-title">IMPORTANT LINKS</h4>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                    <li>
                      <a href="/about">About Us</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/product">Tools</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                    <li>
                      <a href="blogs">Blogs</a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/lifio-ai/" target="_blank">LinkedIn</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p className="copyright text-center mb-0 pb-3">
          Copyright 2023 Lifio Inc. All rights reserved
        </p>
      </section>
    </div>
  );
}

export default Footer;
