
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {url} from "../constants";
const Modals = () => {

    const [full_name, setFull_name] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [product, setProduct] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    let handleOnChange = ( email ) => {

      // don't remember from where i copied this code, but this works.
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
      setEmail(email)
      if ( re.test(email) ) {
        setValidEmail(true)
      }
      else {
          // invalid email, maybe show an error to the user.
          setValidEmail(false)
      }
  }
  
    let handleSubmit = async (e) => {
      e.preventDefault();
      var data = new FormData();
      if(validEmail == false) {
        toast.error("Pleaes enter correct email address");
        return;
      } else if(full_name == "" || mobile == "" || product=="") {
        toast.error("Pleaes enter all details");
        return;
      }
      data.append("full_name", full_name);
      data.append("mobile", mobile);
      data.append("email", email);
      data.append("product", product);
  
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url+"/api/add_request_demo/",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        data: data,
      };
  
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          toast.success("Response submitted!");
        })
        .catch(function (error) {
          console.log(error);
          toast.error("falied " + error.message);
        });
    };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-0">
            {" "}
            <div className="contanct-form shadow-none p-5">
              <h5 className="mb-5">
                {" "}
                Please provide the following information & we will reach out to
                you.
              </h5>
              <div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    value={full_name}
                    placeholder="Name"
                    onChange={(e) => setFull_name(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e.target.value)}
                    required
                  />
                  {email != "" && !validEmail? <p style={{fontSize:"10px", marginTop:0}}>Incorrect email</p>: <></>}
                  <input
                    type="text"
                    value={mobile}
                    placeholder="Mobile Number"
                    onChange={(e) => setMobile(e.target.value)}
                    required
                  />

                  <input
                    type="text"
                    value={product}
                    placeholder="Message"
                    onChange={(e) => setProduct(e.target.value)}
                    required
                  />

                  <div className="text-end">
                    <button className="btn btn-submit px-5" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modals;
