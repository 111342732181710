import React from "react";
import logo from "../Images/Banner/logo.png";
import system_CSAT1 from "../Images/png/Icons System_CSAT.png";
import System_digital_data from "../Images/png/Icons System_digital data.png";
import system_CSAT from "../Images/png/Icons System_automation.png";
import system_CSAT2 from "../Images/Other UI Assets/0V9ciy5g 1.png";
import Modals from "./Modals";

const Header = () => {


  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary py-1 fixed-top bg-white">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav mx-auto">
              <div id="link-1" className="nav-item dropdown-hover mx-2">
                <a className="nav-link dropdown-hover-button" href="/product">
                  Products
                </a>

                <div className="dropdown-hover-content">
                  <div className="row">
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/cdd">
                          <img src={system_CSAT1} alt="" /> Clinical Digital
                          Document (CDD)
                        </a>
                      </div>
                    </div>
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/csat">
                          <img src={System_digital_data} alt="" />
                          Clinical Study Automation Tool (CSAT)
                        </a>
                      </div>
                    </div>
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/sdtm">
                          <img src={system_CSAT} alt="" />
                          SDTM Mapping
                        </a>
                      </div>
                    </div>
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/dcp">
                          <img src={system_CSAT2} alt="" />
                          Data Capture Platform
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-item mx-2 active dropdown-hover">
                <a className="nav-link" href="/services">
                  Services
                </a>
                <div className="dropdown-hover-content">
                  <div className="row">
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/services">
                          <img src={system_CSAT1} alt="" /> Clinical Document Creation
                        </a>
                      </div>
                    </div>
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/services">
                          <img src={System_digital_data} alt="" />
                          Metadata Library Creation
                        </a>
                      </div>
                    </div>
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/services">
                          <img src={system_CSAT} alt="" />
                          EDC Builds for Clinical Trials
                        </a>
                      </div>
                    </div>
                    <div className="row-auto">
                      <div className="mb-3">
                        <a className="dropdown-item p-0" href="/services">
                          <img src={system_CSAT2} alt="" />
                          SAS SDTM Programming
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-item mx-2">
                <a className="nav-link" href="/about">
                  About Us
                </a>
              </div>
              <div className="nav-item mx-2">
                <a className="nav-link" href="/contact">
                  Contact Us
                </a>
              </div>
              <div className="nav-item mx-2">
                <a className="nav-link" href="/blogs">
                  Blogs
                </a>
              </div>
            </div>
            <div>
              <button
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="btn btn-outline-primary px-4"
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </nav>

      <Modals />
    </>
  );
};

export default Header;
