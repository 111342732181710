import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import cdd17 from "../Images/Otherassets/Group 375.png";
import Axios from "axios";
import butterfy from "../Images/png/Butterfly-wings-flap-unscreen.gif";
import Modals from "./Modals";
import {url} from "../constants";

const Blogs = () => {
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    const { data } = await Axios.get(url+"/api/get_blog/");
    const products = data;

    setProducts(products.data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <div>
      <section className="banner-cdd-main">
        <img className="w-full" src={cdd17} />
      </section>
      <section className="banner-blogs">
        <div>
          <div className="row ">
            <div className="col-sm-6 ms-lg-5">
              <div className="product-text px-lg-5 abc4">
                <h3>Blogs</h3>
                <p>
                If you're in the race to get lifesaving drugs to market faster with quality, consider us as the wings beneath your feet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative px-3">
        {products.map((product) => (
          <div className="cards-sevices  container px-0">
            <div className="row  align-items-center">
              <div className="col-sm-4 p-0">
                <div className="service-images-box-1">
                  <img className="w-full" src={url + product.blog_img} alt="" />
                </div>
              </div>
              <div className="col-sm-8 px-lg-5 p-3">
                <div className="product-text ">
                  <p> Blogs | {product.blog_name}</p>
                  <h3>{product.title}</h3>

                  <h6>{parse(product.description)}</h6>
                  <img className="box" src={butterfy} />

                  <button className="btn btn-submit" type="submit">
                    Know More
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>

      <section className="text-center mb-5 px-4">
        <div className="product-text ">
          <p>Want to try our product?</p>
          <button
            className="btn btn-submit"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Request a Demo
          </button>
        </div>
      </section>
      <Modals />
    </div>
  );
};

export default Blogs;
