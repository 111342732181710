import React, { useState } from "react";
import { toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import service1 from "../Images/png/Icons System_Passion.png";
import service2 from "../Images/png/Icons System_Data analysis.png";
import service3 from "../Images/png/Icons System_drung development.png";
import service4 from "../Images/png/Icons System_Clinical MDR.png";
import service5 from "../Images/png/Icons System_Visual define xml editor.png";
import butterfy from "../Images/png/Butterfly-wings-flap-unscreen.gif";
import hand from "../Images/Banner/Light-brown-in-Basic5.png";
import axios from "axios";
import Modals from "./Modals";
import csat from "../Images/Banner/CSAT3.png"
import cdd from "../Images/Banner/CDD3.png"
import sdtm from "../Images/Banner/SDTM3.png"
import dcp from "../Images/Banner/DCP3.png"
import {url} from "../constants";

const Home = () => {
  const [help_me, setHelp_me] = useState("");
  const [full_name, setFull_name] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [query, setQuery] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("help_me", help_me);
    data.append("full_name", full_name);
    data.append("subject", subject);
    data.append("email", email);
    data.append("query", query);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url+"/api/add_leave_message/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        toast.success("SuccessFull");
      })
      .catch(function (error) {
        console.log(error);
        toast.error("falied " + error.message);
      });
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1400: {
        items: 4,
      }
    },
  };
  return (
    <>
      <div>
        <section className="py-20 banner-home">
          <div className="container">
            <div className="row m-0 py-0 justify-content-between">
              <div className="col-sm-4 p-0">
                <div className="banner-box-amin">
                  <img src={hand} className="w-full" />
                  <img className="butter-box" src={butterfy} />
                </div>
              </div>
              <div className="col-sm-7 mt-5">
                <h4 className="mt-5">
                  Creating ground-breaking innovations to help Biopharma companies
                  bring life-saving drugs to market faster.
                </h4>
                <a href="/product" className="btn btn-submit mt-3 py-2 ">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="container py-0">
          <div className="row m-0 py-1 justify-content-between challenge-box">
            <div className="col-sm-6 mt-5"> 
            {/* style={{'position': 'sticky', 'top': 40}}> */}
              <div className="service-text">
                <p>
                  Challenge <img src={butterfy} />
                </p>
                <h3>
                  Today, most of the key processes in clinical trials are done
                  manually, which is not only time consuming but is also not the
                  best use of skilled resources.
                </h3>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="problem-box">
                <div className="problem-cards">
                  <h2>01 </h2>
                  <p>
                    Lack of digitized format of key critical clinical trial
                    documents like Study Protocol, Statistical Analysis Plan
                    (SAP) and Clinical Study Report (CSR).
                  </p>
                </div>
                <div className="problem-cards">
                  <h2>02 </h2>
                  <p>
                    Study build normally takes about 12-14 weeks to go live,
                    involving skilled resources to design the eCRF based on the
                    complexity of the protocol.
                  </p>
                </div>
                <div className="problem-cards">
                  <h2>03 </h2>
                  <p>
                    SDTM mapping is a complex process that often requires
                    mapping with multiple domains. The task is manual and
                    dependent on the knowledge and efficiency of the people
                    undertaking the task, thus taking time and more likely to be
                    prone to errors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mx-lg-5 text-center py-0">
          <div className="solution-box py-lg-0 px-lg-5">
            <div className="service-text px-lg-5">
              <p className="mb-lg-2 mb-0">
                SOLUTION
                <img src={butterfy} />
              </p>
              <h3>
                With our Al-driven SaaS platform, Life Sciences companies will
                be able to save significant time and cost, as well as improve
                quality which is required to build, manage and conduct
                successful trials.
              </h3>
            </div>
          </div>
        </section>

        <section className="bg-box-main">
          {/* <section className="container py-5"> */}
            {/* <div className="row py-5 justify-content-between tools-box">
              <div className="col-sm-5 " style={{'position': 'sticky', 'top': 0}}>
                <div className="service-text">
                  <p>
                    TOOLS <img src={butterfy} />
                  </p>
                  <h3>
                    Our tool helps at every step of the clinical trial from
                    <span> protocol creation to publication </span>
                  </h3>
                  <a href="/product" className="view-product btn px-5 mt-3">
                    View all Products
                  </a>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="tools-seconds">
                  <div className="cards-tools">
                    <h4>Clinical Digital Document</h4>
                    <p>
                      Transforming clinical trial documents into digital formats
                      for better Analytics and Insights
                    </p>
                    <img src={cdd}/>
                  </div>
                  <div className="cards-tools">
                    <h4>Clinical Study Automation Tool</h4>
                    <p>
                      An end-to-end platform to design and develop all the
                      components of a clinical study build process in a single
                      click
                    </p>
                    <img src={csat} style={{'max-width': '100%','max-height': '100%'}}/>
                  </div>
                  <div className="cards-tools">
                    <h4>
                      SDTM Mapping
                    </h4>
                    <p>
                      Transforming the clinical trial data to meet the CDISC
                      SDTM standards
                    </p>
                    <img src={sdtm}/>
                  </div>
                  <div className="cards-tools">
                    <h4>Data Capture Platform (DCP)</h4>
                    <p>
                      Transforming the clinical trial data to meet the CDISC
                      SDTM standards
                    </p>
                    <img src={dcp}/>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row py-0 justify-content-between tools-box2">
              <div className="row">
                <div className="service-text">
                  <p>
                    TOOLS <img src={butterfy} />
                  </p>
                  <h3>
                    Our tool helps at every step of the clinical trial <br/> from 
                    <span> protocol creation to publication. </span>
                  </h3>
                </div>
              </div>
              <div className="row py-5 justify-content-center">
              <div className="col-sm-3" style={{'display':'flex'}}>
                <div className="cards-tools" onClick={() => window.location.href='/cdd'}>
                  <h4>Clinical Digital Document</h4>
                  <p>
                    Transforming clinical trial documents into digital formats
                    for better Analytics and Insights
                  </p>
                  <img src={cdd}/>
                </div>
              </div>
              <div className="col-sm-3" style={{'display':'flex'}}>
              <div className="cards-tools" onClick={() => window.location.href='/csat'}>
                <h4>Clinical Study Automation Tool</h4>
                <p>
                  An end-to-end platform to design and develop all the
                  components of a clinical study build process in a single
                  click.
                </p>
                <img src={csat} style={{'max-width': '100%','max-height': '100%'}}/>
              </div>
              </div>
              <div className="col-sm-3" style={{'display':'flex'}}>
              <div className="cards-tools" onClick={() => window.location.href='/sdtm'}>
                <h4>
                  SDTM Mapping
                </h4>
                <p>
                  Transforming the clinical trial data to meet the CDISC
                  SDTM standards.
                </p>
                <img src={sdtm}/>
              </div>
              </div>
              <div className="col-sm-3" style={{'display':'flex'}} onClick={() => window.location.href='/dcp'}>
              <div className="cards-tools">
                <h4>Data Capture Platform</h4>
                <p>
                  Transforming the clinical trial data to meet the CDISC
                  SDTM standards.
                </p>
                <img src={dcp}/>
              </div>
              </div>
            </div>
            <div className="row">
                <div className="service-text">
                  <a href="/product" className="view-product btn px-5 mt-0">
                    View all Products
                  </a>
                </div>
              </div>
            </div>
          {/* </section> */}
          <section className="text-center py-5">
            <div className="service-text text-center">
              <p>
                SERVICE WE OFFER <img src={butterfy} />
              </p>
              <h3>We innovate with data.</h3>
              <h3>So that your clinical trials conclude faster.</h3>
            </div>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav
              {...options}
            >
              <div className="item">
                <div>
                  <img src={service1} />
                  <h4 className="mb-3">
                    Metadata Library Creation <br /> for all therapeutic areas
                  </h4>

                </div>
                <div className="carousel-button-wrapper">
	  <a href="/services#metadata-library" className="btn btn-submit py-2" >Know More</a>

                </div>
              </div>
              <div className="item">
                <div>
                  <img src={service2} />

                  <h4 className="mb-3">
                    EDC builds for
                    <br /> clinical trials
                  </h4>
                  {/* <button className="btn btn-submit" type="submit">
                    Know More
                  </button> */}
                </div>
                <div className="carousel-button-wrapper">
	  <a href="/services#edc-build" className="btn btn-submit py-2" >Know More</a>
                </div>
              </div>
              <div className="item">
                <div>
                  <img src={service5} />

                  <h4 className="mb-3">
                    Clinical Document <br/> Creation
                  </h4>
                  {/* `<button className="btn btn-submit" type="submit">
                    Know More
                  </button>` */}
                </div>
                <div className="carousel-button-wrapper">
	  <a href="/services#cdc" className="btn btn-submit py-2" >Know More</a>
                </div>
              </div>
              {/* <div className="item">
                <div>
                  <img src={service5} />

                  <h4 className="mb-3">
                    CRF Design. edit check <br /> specs & programming
                  </h4> */}
                  {/* `<button className="btn btn-submit" type="submit">
                    Know More
                  </button>` */}
                {/* </div>
                <div className="carousel-button-wrapper">
	  <a href="/services#crf-design" className="btn btn-submit py-2" >Know More</a>
                </div>
              </div> */}

              <div className="item">
                <div>
                  <img src={service4} />

                  <h4 className="mb-3">
                    SAS SDTM <br /> programming
                  </h4>
                  {/* <button className="btn btn-submit" type="submit">
                    Know More
                  </button> */}
                </div>
                <div className="carousel-button-wrapper">
	  <a href="/services#sas-sdtm" className="btn btn-submit py-2" >Know More</a>
                </div>
              </div>

              {/* <div className="item">
                <div>
                  <img src={service3} />

                  <h4 className="mb-3">
                    Data transformation <br /> in SDTM formats
                  </h4> */}
                  {/* <button className="btn btn-submit" type="submit">
                    Know More
                  </button> */}
                {/* </div>
                <div className="carousel-button-wrapper">
	  <a href="/services#data-transformation" className="btn btn-submit py-2" >Know More</a>
                </div>
              </div> */}
            </OwlCarousel>
            <a href="/services" class="view-product btn px-5 mt-5">
              View all Services
            </a>
          </section>

          <section className="container py-5">
            <div className="row m-0 justify-content-between py-5">
              <div className="col-sm-5">
                <div className="service-text">
                  <p>
                    Contact us <img src={butterfy} />
                  </p>
                  <h3>
                  If you're in the race to get lifesaving drugs to market faster with quality, consider us as the wings beneath your feet.
                  </h3>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="contanct-form">
                  <h5 className="mb-5"> What can we help you with?</h5>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        placeholder="Message  "
                        onChange={(e) => setHelp_me(e.target.value)}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Your Name"
                        onChange={(e) => setFull_name(e.target.value)}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Subject "
                        onChange={(e) => setSubject(e.target.value)}
                        required
                      />

                      <div className="text-end">
                        <button className="btn btn-submit px-5">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      <Modals />
    </>
  );
};

export default Home;
