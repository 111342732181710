import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cdd1 from "../Images/Website UI Icons/PNG/Reduces database startup time.png";
import cdd2 from "../Images/Website UI Icons/PNG/Library.png";
import cdd3 from "../Images/Website UI Icons/PNG/Improves the quality of the build process.png";
import cdd4 from "../Images/Other UI Assets/library.png";
import cdd5 from "../Images/Other UI Assets/generation.png";
import cdd6 from "../Images/Other UI Assets/schedule.png";
import cdd7 from "../Images/Other UI Assets/review.png";
import cdd8 from "../Images/Other UI Assets/user management.png";
import cdd9 from "../Images/Other UI Assets/downloads.png";
import cdd10 from "../Images/Banner/clincalstudy.png";
import System_digital_data from "../Images/png/Icons System_digital data.png";
import cdd11 from "../Images/Other UI Assets/generate ecrf tab ss.png";
import cdd12 from "../Images/Other UI Assets/depict ss.png";
import cdd13 from "../Images/Other UI Assets/predict ss.png";
import cdd14 from "../Images/Otherassets/key benefits 2.png";
import cdd15 from "../Images/Other UI Assets/reports.png";
import cdd16 from "../Images/Other UI Assets/time and event schedule tab ss.png";
import cdd17 from "../Images/Otherassets/Group 375.png";
import cdd18 from "../Images/Other UI Assets/download tab ss.png";
import Modals from "./Modals";

const CSAT = () => {
  return (
    <div>
      <section className="banner-cdd-main">
        <img className="w-full" src={cdd17} />
      </section>

      <section className="banner-csat">
        <div>
          <div className="row ">
            <div className="col-sm-8 ms-lg-5">
              <div className="product-text px-lg-5 abc3">
                <h3>Clinical Study Automation Tool (CSAT)</h3>
                <p>
                  An end-to-end platform to design and develop all the
                  components of a clinical study build process in a single click.
                </p>
                <button
                  className="btn btn-submit col-7"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Request a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 container">
        <div className="row m-0 py-5 align-items-center justify-content-between ">
          <div className="col-sm-6">
            <div className="product-text ">
              <p>
                Developed by Lifio, the Clinical Study Automation Tool
                leverages NLP and ML capabilities to effectively generate the
                study build components. The tool can generate the design of the
                eCRFs specifications and data validation checks directly from
                the clinical protocol, using standard CDASH and therapeutic area
                libraries.
              </p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="cdd-doc-image">
              <img src={System_digital_data} alt="" />
              <h1>3 weeks </h1>
              <p>to build a study</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 position-relative">
        <h3 className="head-cdd py-5">Current Challenges</h3>
        <div className="row mx-0 justify-content-center mb-5">
          <div className="col-sm-3">
            <div className="box-items-about-3">
              <img src={cdd1} alt="" />
              <p>
                Reduces database startup time owing to the AI-driven
                autogenerated eCRFs, Edit checks and SDTM mappings from metadata
                libraries
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="box-items-about-3">
              <img src={cdd2} alt="" />
              <p>Ensures effective management and use of library components</p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="box-items-about-3">
              <img src={cdd3} alt="" />
              <p>Improves the quality of the build process</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 py-5 mb-5 ">
        <h3 className="head-cdd py-5 ">Key benefits</h3>

        <div className="row justify-content-center position-relative">
          <div className="col-sm-8 keybenfits">
            <img className="w-full" src={cdd14} alt="" />
          </div>
        </div>
        <div className="text-center mx-5 mt-5"></div>
      </section>
      <section className="mx-lg-5 my-lg-5 text-center">
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-8">
            <h3 className="head-cdd py-lg-4">Key Features</h3>
            <p className="p-cdd">
              Automated creation of eCRF and other build components from the
              protocol document using AI/ML-driven technology
            </p>

            <Tabs
              defaultActiveKey="statistician"
              transition={false}
              className="mb-5 tab-box justify-content-center border-0 mx-3"
            >
              <Tab eventKey="statistician" title="Generated eCRFs">
                <div className="calss-tab">
                  <img src={cdd11} className="w-full" alt="" />
                  <h3 className="my-lg-5 mx-lg-5">
                    <b> Predicts the edit check </b> rules (data validation
                    rules) to be programmed in the EDC (Electronic Data Capture)
                    system
                  </h3>
                  <img src={cdd13} className="w-full" alt="" />

                  <h3 className="my-lg-5 mx-lg-5">
                    <b> Depicts the SDTM</b> (Study Data Tabulation Model)
                    mapping for the generated eCRFs in the tool
                  </h3>
                  <img src={cdd12} className="w-full" alt="" />
                </div>
              </Tab>
              <Tab eventKey="medical" title="Time & Event Schedule ">
                <div className="calss-tab">
                  <img src={cdd16} className="w-full" alt="" />
                  <h3 className="my-lg-5 mx-lg-5">
                    <b> Predicts the edit check </b> rules (data validation
                    rules) to be programmed in the EDC (Electronic Data Capture)
                    system
                  </h3>
                  <img src={cdd13} className="w-full" alt="" />

                  <h3 className="my-lg-5 mx-lg-5">
                    <b> Depicts the SDTM</b> (Study Data Tabulation Model)
                    mapping for the generated eCRFs in the tool
                  </h3>
                  <img src={cdd12} className="w-full" alt="" />
                </div>
              </Tab>
              <Tab eventKey="data" title="Download">
                <div className="calss-tab">
                  <img src={cdd18} className="w-full" alt="" />
                  <h3 className="my-lg-5 mx-lg-5">
                    <b> Predicts the edit check </b> rules (data validation
                    rules) to be programmed in the EDC (Electronic Data Capture)
                    system
                  </h3>
                  <img src={cdd13} className="w-full" alt="" />

                  <h3 className="my-lg-5 mx-lg-5">
                    <b> Depicts the SDTM</b> (Study Data Tabulation Model)
                    mapping for the generated eCRFs in the tool
                  </h3>
                  <img src={cdd12} className="w-full" alt="" />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>

      <section className="mx-5 my-5 py-5">
        <h3 className="head-cdd py-5 ">Clinical Study Automation Tool Process</h3>

        <div className="row justify-content-center">
          <div className="text-center mx-5 col-sm-9">
            <img className="w-full" src={cdd10} alt="" />
          </div>
        </div>
      </section>

      <section className="mx-lg-5 py-lg -5">
        <h3 className="head-cdd py-lg-5">Key Components</h3>

        <div className="keyfeature mb-5 container">
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <h4 className="px-3">Library Management</h4>
                <p className="px-3">
                  The tool is capable of handling multiple libraries that can be
                  loaded into the tool and efficiently managed there after. The
                  workflow mechanism ensures that the global librarian has the
                  control to maintain the integrity of the library and its
                  validated components.
                </p>
              </div>
              <div className="col-sm-7">
                <img className="w-full" src={cdd4} />
              </div>
            </div>
          </div>
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <img className="w-full" src={cdd5} />
              </div>
              <div className="col-sm-5">
                <h4 className="px-3">eCRF Generation and Version Management</h4>
                <p className="px-3">
                  The tool utilizes advanced NLP capabilities to read the
                  protocol and identify the list of eCRF and associated
                  questions from the library. Its robust ML based model built
                  can predict the contents of the eCRF and continually enhance
                  its learnings based on the user modifications.
                </p>
              </div>
            </div>
          </div>
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <h4 className="px-3">Schedule of Time and Events</h4>
                <p className="px-3">
                  The tool can effectively maintain a schedule of time and
                  events for the study. It does so by allowing the eCRF Designer
                  to extract the time and events table from the protocol and
                  perform the necessary modifications to the table within the
                  tool.
                </p>
              </div>
              <div className="col-sm-7">
                <img className="w-full" src={cdd6} />
              </div>
            </div>
          </div>
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <img className="w-full" src={cdd7} />
              </div>
              <div className="col-sm-5">
                <h4 className="px-3">Workflow for the Review Mechanism</h4>
                <p className="px-3">
                  The tool has a built-in collaboration workflow to ensure that
                  the eCRFs generated are routed through an appropriate review
                  mechanism by the reviewers. It also provides users the ability
                  to enter detailed comments, approve, and reject the study
                  build components.
                </p>
              </div>
            </div>
          </div>
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <h4 className="px-3">Role-Based Access and User Management</h4>

                <p className="px-3">
                  The tool has the capability to create new users and manage
                  them within the system. It can assign them appropriate roles
                  to be performed for the study. This helps in ensuring that the
                  integrity of the study build components is maintained by the
                  review process.
                </p>
              </div>
              <div className="col-sm-7">
                <img className="w-full" src={cdd8} />
              </div>
            </div>
          </div>
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <img className="w-full" src={cdd9} />
              </div>
              <div className="col-sm-5">
                <h4 className="px-3">Downloads</h4>
                <p className="px-3">
	  The solution provides an API framework for end-to-end integration, thereby avoiding the effort of manual data
entry. The tool provides the capability to download the eCRF in native CSV format or in an EDC system
consumable format such as the ALS (Architect Loader Spreadsheet) for Medidata Rave or the CSML format for Oracle
InForm. Additionally, the other downloads include the data validation (edit checks) check specifications for the
study, SDTM mapping document for the study, annotated eCRF in PDF format and  
SDTM executable programs.
                </p>
              </div>
            </div>
          </div>
          <div className="cards-cdd-box p-3">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <h4 className="px-3">Reports and Dashboard</h4>

                <p className="px-3">
                  The tool dashboard furnishes reports to provide an overview of
                  the status and the progress of various studies. It also
                  provides the metadata library reuse reports for user
                  consumption.
                </p>
              </div>
              <div className="col-sm-7">
                <img className="w-full" src={cdd15} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-center mb-5">
        <div className="product-text ">
          <p>Want to try our product?</p>
          <button
            className="btn btn-submit"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Request a Demo
          </button>
        </div>
      </section>
      <Modals />
    </div>
  );
};

export default CSAT;
